<template>
	<div>
		<div class="row">
			<div class="col-7">
				<e-select
					id="status_selected"
					v-model="status_selected"
                    track-by="code"
					label="label"
					:placeholder="$t('monte.congelation.selectionnez_statut')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_status"
					:multiple="true"
				>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-3">
				<e-select
					id="type_date"
					v-model="type_date"
                    track-by="code"
					label="label"
					:placeholder="$t('monte.congelation.selectionnez_type_date')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_type_date"
				>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-2">
				<b-button variant="primary" block @click="load_expeditions">{{ $t('global.rechercher') }}</b-button>
			</div>
			<div class="col-12">
	            <DateRangeV2
            		v-if="type_date"
	                :start.sync="start_date"
	                :end.sync="end_date"
	                :dateSelect="true"
	                :periodToShow="['personalise','jour','mois','mois_dernier']"
	                :submitButton="false"
	            />
			</div>
		</div>

        <CustomTable
            id_table="semence_commande"
            ref="table"
            :items="expeditions"
            :busy.sync="table_busy"
            primaryKey="semenceexpedition_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
            :externSlotColumns="['semenceexpedition_qte', 'semenceexpedition_commentaire', 'demandeur.tiers_tags', 'destinataire.tiers_tags']"
            :callback_one="prete_expedition_checkall"
            :callback_one_deselect="prete_expedition_decheckall"
        >
            <template v-slot:[`custom-slot-cell(semenceexpedition_qte)`]="{ data }">
            	{{ data.semenceexpedition_qte }}
            	<template v-if="data.semenceexpedition_dose">{{ $t('monte.doses') }}</template>
            	<template v-else>{{ $t('monte.paillettes') }}</template>
            </template>
            <template v-slot:[`custom-slot-cell(semenceexpedition_commentaire)`]="{ data }">
            	<span v-html="data.semenceexpedition_commentaire"></span>
            </template>
            <template v-slot:[`custom-slot-cell(demandeur.tiers_tags)`]="{ data }">
				<span v-if="data.demandeur.tags.length > 0" class="tag-manage"> <span v-for="tag in data.demandeur.tags" :key="tag.tags_id" :style="'background-color:'+tag.tags_color" class="tags tags-table"></span> </span>
            </template>
            <template v-slot:[`custom-slot-cell(destinataire.tiers_tags)`]="{ data }">
				<span v-if="data.destinataire.tags.length > 0" class="tag-manage"> <span v-for="tag in data.destinataire.tags" :key="tag.tags_id" :style="'background-color:'+tag.tags_color" class="tags tags-table"></span> </span>
            </template>
        </CustomTable>

		<b-modal ref="modal-cancel" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.confirm_cancel_expedition') }}
			</template>

			<template slot="default">
            	<b>{{ $t('monte.congelation.question_cancel_expedition') }}</b>

            	<div class="row text-center mt-2">
            		<div class="col-12">
			            <b-button variant="primary" @click="confirmCancelExpeditions">
			                <font-awesome-icon v-if="processing_edit" :icon="['fal', 'spinner']" pulse class="mr-1"/><font-awesome-icon v-else :icon="['fal', 'save']" class="mr-1"/>{{ $t('modal.general.ok') }}
			            </b-button>
			            <b-button variant="secondary" class="ml-2" @click="closeModal">
			                <font-awesome-icon :icon="['far', 'times']" class="mr-1"/>{{ $t('modal.general.annuler') }}
			            </b-button>
			        </div>
            	</div>
			</template>
		</b-modal>

		<b-modal ref="modal-differe" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.confirm_differe_expedition') }}
			</template>

			<template slot="default">
            	<b>{{ $t('monte.congelation.question_differe_expedition') }}</b>

            	<div class="row text-center mt-2">
            		<div class="col-12">
			            <b-button variant="primary" @click="confirmDiffereExpeditions">
			                <font-awesome-icon v-if="processing_edit" :icon="['fal', 'spinner']" pulse class="mr-1"/><font-awesome-icon v-else :icon="['fal', 'save']" class="mr-1"/>{{ $t('modal.general.ok') }}
			            </b-button>
			            <b-button variant="secondary" class="ml-2" @click="closeModal">
			                <font-awesome-icon :icon="['far', 'times']" class="mr-1"/>{{ $t('modal.general.annuler') }}
			            </b-button>
			        </div>
            	</div>
			</template>
		</b-modal>

		<b-modal ref="modal-enlevement" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.demande_enlevement') }}
			</template>

			<template slot="default">
            	<div class="row">
            		<div class="col-3">
            			<label for="sur_place" class="col-form-label">{{ $t("monte.congelation.sur_place") }}</label>
			            <b-form-checkbox id="sur_place" v-model="sur_place" />
            		</div>
            		<div class="col-9">
			            <label for="transporteur" class="col-form-label">{{ $t("monte.congelation.transporteur") }}</label>
			            <e-select
			                v-model="transporteur"
			                id="transporteur"
			                track-by="semencetransporteurs_id"
			                :placeholder="$t('monte.congelation.selectionnez_transporteur')"
			                :selectedLabel="$t('global.selected_label')"
			                :options="all_transporteurs"
			                label="semencetransporteurs_label"
			                :searchable="true"
			                :allow-empty="false"
			                :show-labels="false"
			                :disabled="sur_place"
			            />
            		</div>
            		<div v-if="sur_place" class="col-12">
						<div class="form-group">
							<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
							<e-select
								v-model="template"
								id="model_id"
								track-by="model_label"
								label="model_label"
								:placeholder="$t('horse.search_doc')"
								:deselectLabel="$t('global.press_enter_to_remove')"
								:selectedLabel="$t('global.selected_label')"
								:options="templates"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"
							/>
						</div>
					</div>
            		<div class="col-12 mt-2 text-center">
			            <b-button variant="primary" @click="confirmEnlevement">
			                <font-awesome-icon v-if="processing_edit" :icon="['fal', 'spinner']" pulse class="mr-1"/><font-awesome-icon v-else :icon="['fal', 'save']" class="mr-1"/>{{ $t('modal.general.ok') }}
			            </b-button>
			            <b-button variant="secondary" class="ml-2" @click="closeModal">
			                <font-awesome-icon :icon="['far', 'times']" class="mr-1"/>{{ $t('modal.general.annuler') }}
			            </b-button>
			        </div>
            	</div>
			</template>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
    import Navigation from "@/mixins/Navigation.js"
	import Model from "@/mixins/Model.js"
    import { EventBus } from "EventBus"

	export default {
		name: 'CommandeList',
		mixins: [Congelation, ShutterCongelation, Navigation, Model],
		props: ['horse_id'],
		data () {
			return {
				table_busy: false,
				expeditions: [],
				config_table_hrefs: {
					'stallion.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'mare.horse_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'mare.horse_id'
						}
					},
					'demandeur.tiers_rs': {
						routeUniqueName: 'demandeurFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'demandeur.tiers_id'
						}
					},
					'destinataire.tiers_rs': {
						routeUniqueName: 'destinataireFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'destinataire.tiers_id'
						}
					},
					'client.tiers_rs': {
						routeUniqueName: 'clientFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'client.tiers_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToAddExpedition': () => {
						this.setUpAddCommande(this.horse_id)
					},
					'TableAction::goToCancelExpedition': this.openConfirmCancel,
					'TableAction::goToDiffereExpedition': this.openConfirmDiffere,
					'TableAction::goToPrepareExpedition': this.goToPrepareExpedition,
					'TableAction::goToPretExpedition': this.setUpPreparationExpedition,
					'TableAction::goToReintegrerExpedition': this.setUpReintegrerExpedition,
					'TableAction::goToEditExpedition': this.setUpEditExpedition,
					'TableAction::goToDemenceEnlevement': this.openConfirmEnlevement
				},
				all_status: [{
					code: 0,
					label: this.getTrad('monte.congelation.status.cancel')
				},{
					code: 1,
					label: this.getTrad('monte.congelation.status.save')
				},{
					code: 2,
					label: this.getTrad('monte.congelation.status.accept')
				},{
					code: 3,
					label: this.getTrad('monte.congelation.status.en_cours')
				},{
					code: 4,
					label: this.getTrad('monte.congelation.status.ready')
				// },{
				// 	code: 5,
				// 	label: this.getTrad('monte.congelation.status.waiting')
				// },{
				// 	code: 6,
				// 	label: this.getTrad('monte.congelation.status.expedie')
				// },{
				// 	code: 7,
				// 	label: this.getTrad('monte.congelation.status.livre')
				// },{
				// 	code: 8,
				// 	label: this.getTrad('monte.congelation.status.facture')
				}],
				status_selected: [],
				all_type_date: [{
					code: 'semenceexpedition_expedition_date',
					label: this.getTrad('monte.congelation.date.semenceexpedition_expedition_date')
				},
				{
					code: 'semenceexpedition_inserted_date',
					label: this.getTrad('monte.congelation.date.semenceexpedition_inserted_date')
				}],
				type_date: null,
				start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
				end_date: new Date(),
				expedition_edit: [],
				processing_edit: false,
				all_transporteurs: [],
				transporteur: null,
				sur_place: false,
				templates: [],
				template: null,
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.status_selected = this.all_status.filter(st => [1, 2].includes(st.code))
				this.load_expeditions()
				this.all_transporteurs = await this.getAllTransporteurs()
			},

			async load_expeditions() {
				if(this.status_selected.length == 0) {
					return false
				}
				this.table_busy = true
				const expeditions = await this.getExpeditions(this.horse_id, this.status_selected.map(st => st.code), this.type_date, this.start_date, this.end_date)
				expeditions.forEach(exp => {
					exp.statut = this.getTrad('monte.congelation.status.'+exp.semenceexpedition_status)
				})
				this.expeditions = expeditions
				this.table_busy = false
				if(this.$refs.table) {
					this.$refs.table.unselectAll()
				}
			},

			async openConfirmCancel(expeditions) {
				this.expedition_edit = expeditions
				this.$refs['modal-cancel'].show()
			},

			async confirmCancelExpeditions() {
				this.processing_edit = true
				
				await this.cancelExpedition(this.expedition_edit.map(exp => exp.semenceexpedition_id))
				this.successToast('toast.info_save_succes')
				this.processing_edit = false
				this.$refs['modal-cancel'].hide()
				this.load_expeditions()
			},

			async openConfirmDiffere(expeditions) {
				this.expedition_edit = expeditions
				this.$refs['modal-differe'].show()
			},

			async confirmDiffereExpeditions() {
				this.processing_edit = true
				
				await this.differeExpedition(this.expedition_edit.map(exp => exp.semenceexpedition_id))
				this.successToast('toast.info_save_succes')
				this.processing_edit = false
				this.$refs['modal-differe'].hide()
				this.load_expeditions()
			},

			closeModal() {
				this.$refs['modal-cancel'].hide()
			},

			async goToPrepareExpedition(expeditions) {
				await this.prepareExpeditions(expeditions.map(exp => exp.semenceexpedition_id).join(','))
				EventBus.$emit('TableAction::stopSpin', true)
				this.successToast('toast.info_save_succes')
				this.load_expeditions()
			},

            prete_expedition_checkall(item) {
                if(item.length > 0 && item[0].semenceexpedition_status == 4) {
					const destinataire_id = item[0].semenceexpedition_destinataire
					const same_expedition = this.expeditions.filter(exp => exp.semenceexpedition_status == 4 && exp.semenceexpedition_destinataire == destinataire_id)
					this.$refs.table.manual_check(same_expedition)
				}
            },

            prete_expedition_decheckall(item) {
                if(item.length > 0 && item[0].semenceexpedition_status == 4) {
					const destinataire_id = item[0].semenceexpedition_destinataire
					const same_expedition = this.expeditions.filter(exp => exp.semenceexpedition_status == 4 && exp.semenceexpedition_destinataire == destinataire_id)
					this.$refs.table.manual_decheck(same_expedition)
				}
			},

			async openConfirmEnlevement(expeditions) {
				this.expedition_edit = expeditions
				this.template = null
				this.$refs['modal-enlevement'].show()
				this.templates = await this.loadAllModel(0, ['demande_enlevement'])
			},

			async confirmEnlevement() {
				this.processing_edit = true

				if((!this.sur_place && !this.transporteur) || (this.sur_place && !this.template)) {
					return false
				}

				const transporteur_id = !this.sur_place ? this.transporteur.semencetransporteurs_id : null
				const model_id = this.template ? this.template.model_id : null
				
				await this.demandeEnlevement(this.expedition_edit.map(exp => exp.semenceexpedition_id), transporteur_id, model_id)
				this.successToast('toast.info_save_succes')
				this.processing_edit = false
				this.$refs['modal-enlevement'].hide()
				this.load_expeditions()
				this.transporteur = null
				this.sur_place = false
			}
		},

		watch: {
			sur_place(val) {
				if(val) {
					this.transporteur = null
				}
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2')
		}
	}

</script>